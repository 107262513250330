import { Input} from 'antd';
import React,{useEffect,useState} from 'react';
import './wash.css'; 
import {PlusCircleFilled,CameraFilled,DeleteOutlined } from '@ant-design/icons';
import API from '../../../../api'
import Add from "../../../../images/yard_assets/addnew_row_icon.svg"
import Delete from "../../../../images/yard_assets/Delete icon.svg"


const SafetyBox=({safety,setSafety})=>{
    const {uploadImage}=API
    const addMore=()=>{
       const _safety= [...safety]
       _safety.push({url:'',code:'',remark:''})
        setSafety(_safety)
    }
    const deleteSafety=(i)=>{
        const _safety= [...safety]
        _safety.splice(i,1)
         setSafety(_safety)  
    }
    const imageUpload=(e,item)=>{
        const formData=new FormData()
        formData.append('file',e.target.files[0])
        uploadImage(formData).then((res)=>{
            item.url=res.data.data.filepath
        })
    }
    return(
        <div className="wash_food_yard_section">
            <h3>Install safety Seals & Record Below</h3>
             <p>Tag # ONLMO11 (Enter last 3)</p>
             {safety&&safety.map((item,i)=><>
             <div className="service_details" >
             <Input style={{width:'25%',padding:'20px 0px'}} onChange={(e)=>imageUpload(e,item)} type="file" placeholder="Add a Picture" suffix={<CameraFilled style={{fontSize:'18px'}} />}/>
             <div className='wash_food_yard_section_int'>
             <label>Enter Input</label>
             <Input style={{width:'100%'}} onChange={(e)=>item.code=e.target.value} placeholder="Enter Input"/>
             </div>
             <div className='wash_food_yard_section_rem'>
             <label>Add Remarks</label>
             <Input style={{width:'100%'}} onChange={(e)=>item.remark=e.target.value} placeholder="Add Remarks"/>
             </div>
             </div>
             <div className='wash_food_yard_section_delt_add'>
             {safety.length===i+1?
             <div onClick={()=>addMore()}>
              <img src={Add} alt='add icon'/> 
              <span>add new row</span>    
            </div>
             :
             <img onClick={()=>deleteSafety(i)} src={Delete} alt="delete icon"/>
             }
             </div>
             </>
             )}
        </div>
    )
}

export default SafetyBox;